<template>
  <div class="container">
    <div class="row justify-content-center pt-5 pb-3">
      <div class="col-9 col-xl-7 position-relative g-0">
        <div
          class="
            blended rounded
            d-flex
            flex-column
            justify-content-between
            text-white
            top-0
            start-0
            p-3
            w-100
          "
        >
          <div
            class="
              d-flex
              flex-column
              text-start
              pt-3
              pb-2
              px-2 px-xl-4         
              dynamic-font
            "
          >
            <span
              ><u>Welcome to Speechclash</u> - A quick, strategic card battler with RPG elements that sharpen your language skills as you play:</span
            >
            <hr class="m-3 mx-4" />
            <ul>
              <li>Fast-Paced Card Duels</li>
              <li>Linguistic Challenges</li>
              <li>RPG Progression</li>
              <li>Dynamic Gameplay</li>
            </ul>
            <hr class="mb-3 mt-1 mx-4" />
            <span>COMING SOON</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Subscriber",
  data() {
    return { };
  }
};
</script>
<style scoped>
.blended {
  background-image: linear-gradient(0deg, hsla(0, 0%, 0%, .5), hsla(0, 0%, 0%, .5));
  z-index: -1;
  mix-blend-mode: overlay;
}
.dynamic-font {
  font-size: calc(14px + (26 - 14) * ((100vw - 300px) / (3440 - 300)));
  /*font-family: PTSans;
  text-shadow: 1px 0px 1px black, 0px 1px 1px black, -1px 0px 1px black,
    0px -1px 1px black, 0px 4px 4px black;*/
}
</style>

